const Candidancy = () => import('../views/Candidancy.vue')
const CV = () => import('../views/CV.vue')
const Details = () => import('../views/Details.vue')
const Profile = () => import('../views/Profile.vue')
const Publish = () => import('../views/Publish.vue')
const Ranking = () => import('../views/Ranking.vue')
const Vacancy = () => import('../views/Vacancy.vue')
const ProfileUnsolicited = () => import('../views/ProfileUnsolicited.vue')
const unsolicited = () => import('../views/Unsolicited.vue')

export default [
  {
    path: 'entity/candidacies',
    component: Candidancy,
    meta: { requiresAuth: true },
    name: 'candidacies'
  },
  {
    path: 'entity/cv/:id',
    component: CV,
    meta: { requiresAuth: true },
    name: 'candidacies.cv',
    props: true,
  },
  {
    path: 'entity/details/:id',
    component: Details,
    meta: { requiresAuth: true },
    name: 'datails',
    props: true,
  },
  {
    path: 'entity/candidacies/profile/:id',
    component: Profile,
    meta: { requiresAuth: true },
    name: 'candidacy.profile',
    props: true
  },
  {
    path: 'entity/ranking/profile/:id',
    component: Profile,
    meta: { requiresAuth: true },
    name: 'ranking.profile',
    props: true
  },
  {
    path: 'entity/publish',
    component: Publish,
    meta: { requiresAuth: true },
    name: 'publish'
  },
  {
    path: 'entity/ranking',
    component: Ranking,
    meta: { requiresAuth: true },
    name: 'ranking'
  },
  {
    path: 'entity/vacancies',
    component: Vacancy,
    meta: { requiresAuth: true },
    name: 'vacancies'
  },
  {
    path: 'entity/candidacies/unsolicited',
    component: unsolicited,
    meta: { requiresAuth: true },
    name: 'vacancies'
  },
  {
    path: 'entity/candidacies/unsolicited/profile/:id',
    component: ProfileUnsolicited,
    meta: { requiresAuth: true },
    name: 'candidacy.profile',
    props: true
  },
]