module.exports = {
  academicEducation: "Formação académica",
  availability: "Disponibilidade",
  languages: "Línguas",
  professionalExperience: "Anos de experiência",
  year: "Ano",
  years: "Anos",
  postingForm: "Formulário de publicação",
  toView: "Visualização",
  clienteName: "Nome do cliente",
  typeVacancy: "Tipo de anúncio",
  category: "Categoria",
  jobTitle: "Título da vaga",
  language: "Língua",
  level: "Nivel",
  academicEdu: "Formação académica",
  yearExperiencie: "Anos de experiência",
  provinceState: "Província/Município",
  enterJobDesc: "Insira a desacrição da vaga",
  startDate: "Data inicial",
  endDate: "Data final",
  tvacancy: ["Emprego", "Estágio"],
  categoryOptions: ["Tempo inteiro", "Parcial", "Freelancer"],
  languageOptions: ["Português", "Inglês", "Françês", "Alemão", "Espanhol"],
  levelLanguageOptions: ["Básico", "Intermédio", "Avançado"],
  levelLanguageOptions1: [ 
    { text: "Básico", key: "basic" },
    { text: "Intermédio", key: "intermediate" },
    { text: "Avançado", key: "advanced" },
  ],
  levelAcademicOptionsPT: [ 
    { key: "basic", text: "Básico" },
    { key: "elementary", text: "Elementar"},
    { key: "dedium", text: "Médio" },
    { key: "bachelor", text: "Bacharelato" },
    { key: "graduation", text: "Licenciatura" },
    { key: "master_degree", text: "Mestrado" },
    { key: "doctorate", text: "Doutoramento" },
    { key: "Phd", text: "Phd" },
  ],
  levelAcademicOptions: ["Básico geral", "Médio geral", "Médio técnico", "Licenciatura", "Mestrado", "Phd"],
  requiredField: "Campo obrigatoria",
  finish: "Finalizar",
  publish: "Publicar",
  closeSave: "Salvar como rascunho",
  proceed: "prosseguir",
  alert:"Complete o seu perfil para poder continuar",
  weight:"Pontuação",
  area:"Áreas afins"
}