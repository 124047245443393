import areasExpertisePT from './../library/areas-expertise-pt'
import areasExpertiseEN from './../library/areas-expertise-en'
import countiesMZ from './../library/counties-mz'
import countriesPT from './../library/countries-pt'
import nationalitiesPT from './../library/nationalities-pt'
import nationalitiesEN from './../library/nationalities-en'
import positionsPT from './../library/positions-pt'
import positionsEN from './../library/positions-en'
import trainingAreasEN from "./../library/training-areas-en"
import trainingAreasPT from "./../library/training-areas-pt"
import publishingLangEN from './../library/publishing-lang-en'
import publishingLangPT from './../library/publishing-lang-pt'
import {
  levelAcademicOptionsPT
} from './../library/publishing-lang-pt'
import {
  levelAcademicOptionsEN
} from './../library/publishing-lang-en'
import genderPT from './../library/gender_pt'
import genderEN from './../library/gender_en'
import courseEN from './../library/training-course-en'
import coursePT from './../library/training-course-pt'

import provincePT from './../library/province-pt'
import provinceEN from './../library/province-en'

export const library = {
  namespaced: true,
  state: {
    areasExpertisePT,
    countiesMZ,
    countriesPT,
    nationalitiesPT,
    nationalitiesEN,
    positionsPT,
    positionsEN,
    publishingLangEN,
    publishingLangPT,
    trainingAreasEN,
    trainingAreasPT,
    areasExpertiseEN,
    genderPT,
    genderEN,
    courseEN,
    coursePT,
    levelAcademicOptionsPT,
    levelAcademicOptionsEN,
    provincePT,
    provinceEN

  },
  actions: {},
  mutations: {},
  getters: {
    areasExpertise: (state) => (locale) => {
      switch (locale) {
        case 'pt':
          return state.areasExpertisePT;
        case 'en':
          return state.areasExpertiseEN;
      }
    },
    countiesMZ: (state) => {
      return state.countiesMZ;
    },
    countries: (state) => (locale) => {
      switch (locale) {
        case 'pt':
          return state.countriesPT;
      }
    },
    nationalities: (state) => (locale) => {
      switch (locale) {
        case 'pt':
          return state.nationalitiesPT;
        case 'en':
          return state.nationalitiesEN;
      }
    },
    provinces: (state) => (locale) => {
      switch (locale) {
        case 'pt':
          return state.provincePT;
        case 'en':
          return state.provinceEN;
      }
    },
    getLevelAcademic: (state) => (locale) => {
      switch (locale) {
        case 'pt':
          return state.levelAcademicOptionsPT;
        case 'en':
          return state.levelAcademicOptionsEN;
      }
    },
    positions: (state) => (locale) => {
      switch (locale) {
        case 'en':
          return state.positionsEN;
        case 'pt':
          return state.positionsPT;
      }
    },
    publishingLang: (state) => (locale) => {
      switch (locale) {
        case 'en':
          return state.publishingLangEN;
        case 'pt':
          return state.publishingLangPT;
      }
    },
    trainingAreas: (state) => (locale) => {
      switch (locale) {
        case 'en':
          return state.trainingAreasEN;
        case 'pt':
          return state.trainingAreasPT;
      }
    },
    gender: (state) => (locale) => {
      switch (locale) {
        case 'en':
          return state.genderEN;
        case 'pt':
          return state.genderPT;
      }
    },
    trainingCourses: (state) => (locale) => {
      switch (locale) {
        case 'en':
          return state.courseEN;
        case 'pt':
          return state.coursePT;
      }
    }
  }
}