module.exports = {
  academicEducation: "Academic education",
  availability: "Availability",
  languages: "Languages",
  professionalExperience: "Professional Experience",
  year: "Year",
  years: "Years",
  postingForm: "Posting form",
  toView: "To view",
  clienteName: "Select client",
  typeVacancy: "Post type",
  category: "Category",
  jobTitle: "Job Title",
  language: "Language",
  level: "Level",
  academicEdu: "Academic education",
  yearExperiencie: "Years of experience",
  provinceState: "Province/County",
  enterJobDesc: "Enter the job description",
  startDate: "Start date",
  endDate: "End date",
  tvacancy: ["Job ofering", "Internship offer"],
  categoryOptions: ["Fulltime", "Part time", "Freelancer"],
  languageOptions: ["Portuguese", "English", "French", "German", "Spanish"],
  levelLanguageOptions1: [ 
    { text: "Basic", key: "basic" },
    { text: "Intermediate", key: "intermediate" },
    { text: "Advanced", key: "advanced" },
  ],
  levelAcademicOptionsEN: [ 
    { key: "basic", text: "Basic" },
    { key: "elementary", text: "Elementary"},
    { key: "dedium", text: "Medium" },
    { key: "bachelor", text: "Bachelor" },
    { key: "graduation", text: "Graduation" },
    { key: "master_degree", text: "Master degree" },
    { key: "doctorate", text: "Doctorate" },
    { key: "Phd", text: "Phd" },
  ],
  levelLanguageOptions: ["Basic", "Intermediate", "Advanced"],
  levelAcademicOptions: ["Twelve great or Less", "Twelve great", "Tech trening", "Degree", "Master degree", "Phd"],
  requiredField: "Required field",
  finish: "Finish",
  publish: "Publish",
  closeSave: "Save as draft",
  proceed: "proceed",
  alert:"Complete your profile to be able to continue",
  weight:"Score",
  area:"Related areas"
}