module.exports = [
  { text: "Administração agrícola", key: "area_expertise_1" },
  { text: "Administração de serviços", key: "area_expertise_2" },
  { text: "Administração governamental", key: "area_expertise_3" },
  { text: "Agricultura", key: "area_expertise_4" },
  { text: "Alimentos e bebidas", key: "area_expertise_5" },
  { text: "Animação", key: "area_expertise_6" },
  { text: "Armazenagem", key: "area_expertise_7" },
  { text: "Arquitetura e planejamento", key: "area_expertise_8" },
  { text: "Arrecadação de recursos", key: "area_expertise_9" },
  { text: "Artes cênicas", key: "area_expertise_10" },
  { text: "Artes e artesanato", key: "area_expertise_11" },
  { text: "Atacado", key: "area_expertise_12" },
  { text: "Atendimento ao consumidor", key: "area_expertise_13" },
  { text: "Atendimento médico e hospitalar", key: "area_expertise_14" },
  { text: "Atendimento médico psiquiátrico", key: "area_expertise_15" },
  { text: "Atividades parlamentares", key: "area_expertise_16" },
  { text: "Automação industrial", key: "area_expertise_17" },
  { text: "Aviação e aeroespacial", key: "area_expertise_18" },
  { text: "Bancos", key: "area_expertise_19" },
  { text: "Bancos de investimento", key: "area_expertise_20" },
  { text: "Belas-artes", key: "area_expertise_21" },
  { text: "Bens de consumo", key: "area_expertise_22" },
  { text: "Bibliotecas", key: "area_expertise_23" },
  { text: "Biotecnologia", key: "area_expertise_24" },
  {
    text: "Capital de risco e participações privadas",
    key: "area_expertise_25",
  },
  { text: "Cinema e filmes", key: "area_expertise_26" },
  {
    text: "Comércio e desenvolvimento internacional",
    key: "area_expertise_27",
  },
  { text: "Composição e revisão de textos", key: "area_expertise_28" },
  { text: "Construção", key: "area_expertise_29" },
  { text: "Construção de ferrovia", key: "area_expertise_30" },
  { text: "Construção naval", key: "area_expertise_31" },
  { text: "Consultoria de gerenciamento", key: "area_expertise_32" },
  { text: "Contabilidade", key: "area_expertise_33" },
  { text: "Cosmética", key: "area_expertise_34" },
  { text: "Defesa e espaço", key: "area_expertise_35" },
  { text: "Desenvolvimento de programas", key: "area_expertise_36" },
  { text: "Design", key: "area_expertise_37" },
  { text: "Design gráfico", key: "area_expertise_38" },
  { text: "Dispositivos médicos", key: "area_expertise_39" },
  { text: "Editoração", key: "area_expertise_40" },
  { text: "Educação à distância", key: "area_expertise_41" },
  { text: "Embalagens e recipientes", key: "area_expertise_42" },
  { text: "Engenharia civil", key: "area_expertise_43" },
  { text: "Engenharia mecânica ou industrial", key: "area_expertise_44" },
  { text: "Ensino fundamental/médio", key: "area_expertise_45" },
  { text: "Ensino superior", key: "area_expertise_46" },
  { text: "Entrega de encomendas e fretes", key: "area_expertise_47" },
  { text: "Entretenimento", key: "area_expertise_48" },
  { text: "Esportes", key: "area_expertise_49" },
  { text: "Execução da lei", key: "area_expertise_50" },
  { text: "Filantropia", key: "area_expertise_51" },
  { text: "Fotografia", key: "area_expertise_52" },
  { text: "Gabinete presidencial", key: "area_expertise_53" },
  { text: "Gestão de investimentos", key: "area_expertise_54" },
  {
    text: "Gestão de organização sem fins lucrativos",
    key: "area_expertise_55",
  },
  { text: "Gestão educacional", key: "area_expertise_56" },
  { text: "Hardware", key: "area_expertise_57" },
  { text: "Hotelaria", key: "area_expertise_58" },
  { text: "Imobiliário", key: "area_expertise_59" },
  { text: "Imóveis comerciais", key: "area_expertise_60" },
  { text: "Importação e exportação", key: "area_expertise_61" },
  { text: "Impressão", key: "area_expertise_62" },
  { text: "Indústria automotiva", key: "area_expertise_63" },
  { text: "Indústria farmacêutica", key: "area_expertise_64" },
  { text: "Indústria química", key: "area_expertise_65" },
  { text: "Indústria têxtil", key: "area_expertise_66" },
  { text: "Instalações e serviços de recreação", key: "area_expertise_67" },
  { text: "Instituições religiosas", key: "area_expertise_68" },
  { text: "Internet", key: "area_expertise_69" },
  { text: "Jogos de azar", key: "area_expertise_70" },
  { text: "Jogos de computador", key: "area_expertise_71" },
  { text: "Jornais", key: "area_expertise_72" },
  { text: "Judiciário", key: "area_expertise_73" },
  { text: "Laticínios", key: "area_expertise_74" },
  { text: "Lazer, viagens e turismo", key: "area_expertise_75" },
  { text: "Linhas aéreas/Aviação", key: "area_expertise_76" },
  { text: "Logística e cadeia de suprimentos", key: "area_expertise_77" },
  { text: "Manufatura de eletroeletrônicos", key: "area_expertise_78" },
  { text: "Maquinário", key: "area_expertise_79" },
  { text: "Marketing e publicidade", key: "area_expertise_80" },
  { text: "Materiais de construção", key: "area_expertise_81" },
  { text: "Materiais esportivos", key: "area_expertise_82" },
  { text: "Medicina alternativa", key: "area_expertise_83" },
  { text: "Mercados de capital", key: "area_expertise_84" },
  { text: "Mídia de difusão", key: "area_expertise_85" },
  { text: "Mídia online", key: "area_expertise_86" },
  { text: "Militar", key: "area_expertise_87" },
  { text: "Mineração e metais", key: "area_expertise_88" },
  { text: "Móveis", key: "area_expertise_89" },
  { text: "Museus e instituições", key: "area_expertise_90" },
  { text: "Música", key: "area_expertise_91" },
  { text: "Nanotecnologia", key: "area_expertise_92" },
  { text: "Organização cívica e social", key: "area_expertise_93" },
  { text: "Organização política", key: "area_expertise_94" },
  { text: "Organizações de pesquisa e orientação", key: "area_expertise_95" },
  { text: "Papel e produtos florestais", key: "area_expertise_96" },
  { text: "Pesca", key: "area_expertise_97" },
  { text: "Pesquisa", key: "area_expertise_98" },
  { text: "Pesquisa de mercado", key: "area_expertise_99" },
  { text: "Petróleo e energia", key: "area_expertise_100" },
  { text: "Plástico", key: "area_expertise_101" },
  { text: "Política pública", key: "area_expertise_102" },
  { text: "Prática jurídica", key: "area_expertise_103" },
  { text: "Prática médica", key: "area_expertise_104" },
  { text: "Produção de mídia", key: "area_expertise_105" },
  { text: "Produtos alimentícios", key: "area_expertise_106" },
  { text: "Produtos de luxo e joias", key: "area_expertise_107" },
  { text: "Produtos eletrônicos", key: "area_expertise_108" },
  { text: "Recrutamento e seleção", key: "area_expertise_109" },
  { text: "Recursos humanos", key: "area_expertise_110" },
  { text: "Recursos renováveis e meio ambiente", key: "area_expertise_111" },
  { text: "Rede sem fio", key: "area_expertise_112" },
  { text: "Redes", key: "area_expertise_113" },
  { text: "Relações governamentais", key: "area_expertise_114" },
  { text: "Relações internacionais", key: "area_expertise_115" },
  { text: "Relações públicas e comunicações", key: "area_expertise_116" },
  { text: "Resolução alternativa de litígios", key: "area_expertise_117" },
  { text: "Restaurantes", key: "area_expertise_118" },
  { text: "Roupas e moda", key: "area_expertise_119" },
  { text: "Saúde, bem-estar e educação física", key: "area_expertise_120" },
  { text: "Segurança de redes e computadores", key: "area_expertise_121" },
  { text: "Segurança e investigações", key: "area_expertise_122" },
  { text: "Segurança pública", key: "area_expertise_123" },
  { text: "Seguros", key: "area_expertise_124" },
  { text: "Semicondutores", key: "area_expertise_125" },
  { text: "Serviços ambientais", key: "area_expertise_126" },
  { text: "Serviços da informação", key: "area_expertise_127" },
  { text: "Serviços financeiros", key: "area_expertise_128" },
  { text: "Serviços individuais e familiares", key: "area_expertise_129" },
  { text: "Serviços jurídicos", key: "area_expertise_130" },
  { text: "Serviços para eventos", key: "area_expertise_131" },
  { text: "Serviços públicos", key: "area_expertise_132" },
  { text: "Software", key: "area_expertise_133" },
  { text: "Supermercados", key: "area_expertise_134" },
  {
    text: "Suprimentos e equipamentos comerciais",
    key: "area_expertise_135",
  },
  { text: "Tabaco", key: "area_expertise_136" },
  { text: "Tecnologia da informação e serviços", key: "area_expertise_137" },
  { text: "Telecomunicações", key: "area_expertise_138" },
  { text: "Terceirização e offshoring", key: "area_expertise_139" },
  { text: "Tradução e localização", key: "area_expertise_140" },
  { text: "Transporte marítimo", key: "area_expertise_141" },
  { text: "Transporte/Caminhões/Trens", key: "area_expertise_142" },
  {
    text: "Treinamento e orientação profissional",
    key: "area_expertise_143",
  },
  { text: "Varejo", key: "area_expertise_144" },
  { text: "Veterinária", key: "area_expertise_145" },
  { text: "Vidro, cerâmica e concreto", key: "area_expertise_146" },
  { text: "Vinhos e destilados", key: "area_expertise_147" },
];
