import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@iconify/vue';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#0098CE", // 141838
        primary2: "#F5FAFF", // 141838
        primary_text: "#DAF4FD", // 141838
        secondary: "#104E89", //F7A400
        navbar: "#FFFFFF",
        error: "#b71c1c",
        info: "#000",
      },
    },
  },
};

const vuetify = new Vuetify(opts)

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md'
})

export default vuetify