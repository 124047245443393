import authRoute from './../modules/auth/router'
import candidateRoute from './../modules/candidate/router'
import clientRoute from './../modules/client/router'
import dashboardRoute from './../modules/dashboard/router'
import interviewRoute from './../modules/interview/router'
import settingRoute from './../modules/setting/router'
import testRoute from './../modules/test/router'
import vacancyRoute from './../modules/vacancy/router'
const CV = () => import('./../views/CV.vue')
const pdf = () => import('./../views/CVPDF.vue')
const note = () => import('./../views/note.vue')
const candidateNote = () => import('./../views/candidateNote');
const Dashboard = () => import('./../views/Dashboard.vue')
const Vacancy = () => import('./../views/Vacancy.vue')
const vacancies = () => import("./../views/vacancies.vue");

export default [
  {
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      ...authRoute,
      ...candidateRoute,
      ...clientRoute,
      ...dashboardRoute,
      ...interviewRoute,
      ...settingRoute,
      ...testRoute,
      ...vacancyRoute,
    ]
  },
  {
    path: '/candidate/:id',
    component: CV,
    name: 'candidate.cv',
    props: true
  },
  {
    path: '/pdf/:id',
    component: pdf,
    name: 'pdf.cv',
    props: true
  },
  {
    path: '/dashboard/entity/note/vacancyId/:vacancyId/candidateId/:candidateId/type/:type/vacancy/:title/candidate/:fullname',
    component: note,
    name: 'note',
    props: true
  },
  {
    path: '/dashboard/entity/candidateNote/candidateId/:candidateId/candidate/:fullname',
    component: candidateNote,
    name: 'candidateNote',
    props: true
  },
  {
    path: '/cv/:id',
    component: CV,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/vacancy/:id',
    component: Vacancy,
    props: true
  },
  {
    path: "/entity/:id",
    component: vacancies,
    props: true,
  },
]